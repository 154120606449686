import "./global.css";
import "./index.css";
import "./images/logo.png";
import "./images/app/android.png";
import "./images/app/ios.png";
import "./images/app/qrcode.png";
import "./images/hero.png";
import "./images/background.png";
import "./images/feature/map.png";
import "./images/feature/chat.png";
import "./images/feature/people.png";
import "./images/award.png";

import "./images/milestone/202412_3.png";
import "./images/milestone/202412_2.png";
import "./images/milestone/202412_1.png";
import "./images/milestone/202411.png";
import "./images/milestone/202406.png";
import "./images/milestone/202403.png";
import "./images/milestone/202401.png";
import "./images/milestone/202309.png";
import "./images/milestone/202303.png";
import "./images/milestone/202211_2.png";
import "./images/milestone/202211_1.png";
import "./images/milestone/202202.png";
import "./images/milestone/202102.png";

import "./images/activity/202409_1.png";
import "./images/activity/202409_2.png";
import "./images/activity/202409_3.png";
import "./images/activity/202409_4.png";
import "./images/activity/202411_1.png";
import "./images/activity/202411_2.png";
import "./images/activity/202412.png";

import "./images/video/video_1.png";
import "./images/video/video_2.png";
import "./images/video/video_3.png";

import "./images/news/news_1.png";
import "./images/news/news_2.png";
import "./images/news/news_3.png";
import "./images/news/news_4.png";
import "./images/news/news_5.png";
import "./images/news/news_6.webp";

import "./images/development/event_1.png";
import "./images/development/event_2.png";
import "./images/development/event_3.png";
import "./images/development/event_4.png";
import "./images/development/company.jpg";
import "./images/development/community.jpg";

import "./images/fb.png";
import "./images/qa.png";
import "./images/qa/concept.png";
import "./images/qa/st.png";
import "./images/qa/logo.png";
import "./images/qa/mascot.png";

import AOS from "aos";
import "aos/dist/aos.css";

import "./images/favicon_io/apple-touch-icon.png";

console.log("Hello, world!");

import Swiper from "swiper";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import Swal from "sweetalert2";

const handleChekcUserAgent = () => {
  if (/(iPhone|iPad|iOS)/i.test(navigator.userAgent)) {
    return "iOS";
  } else if (/(Android)/i.test(navigator.userAgent)) {
    return "Android";
  } else {
    return "PC";
  }
};

const sendEmail = () => {
  const email = document.getElementById("contact_email").value;
  const name = document.getElementById("contact_name").value;
  const subject = document.getElementById("contact_subject").value;
  const content = document.getElementById("contact_text").value;
  const submitBtn = document.getElementById("contact_submit");
  console.log(submitBtn);
  const emailRegx =
    /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
  const isVaildEmail = emailRegx.test(email);

  if (!email || !name || !subject || !content) {
    Swal.fire({
      icon: "error",
      title: "錯誤",
      text: "請填入空缺欄位",
      confirmButtonText: "確認",
      confirmButtonColor: "#ff6666",
    });
  } else if (!isVaildEmail) {
    Swal.fire({
      icon: "error",
      title: "錯誤",
      text: "請填入正確的Email格式",
      confirmButtonText: "確認",
      confirmButtonColor: "#ff6666",
    });
  } else {
    submitBtn.disabled = true;
    const templateParams = {
      name: name,
      email: email,
      subject: subject,
      message: content,
    };

    emailjs
      .send("service_kywivgf", "template_26uji7b", templateParams)
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            position: "end",
            icon: "success",
            title: "感謝您的來信",
            showConfirmButton: false,
            timer: 1500,
          });
          submitBtn.disabled = false;
        } else {
          console.log("FAILED...", res.text);
        }
      });
  }
};

document.addEventListener("DOMContentLoaded", function () {
  AOS.init({
    duration: 1200,
    once: true,
    offset: 200,
    delay: 100,
  });
  handleChekcUserAgent();

  const submitBtn = document.getElementById("contact_submit");
  submitBtn.addEventListener("click", sendEmail);

  const hamburgers = document.querySelector(".mob-nav-icon");
  const nav = document.querySelector("nav");

  window.addEventListener("resize", () => {
    nav.classList.remove("transition");
    setTimeout(() => {
      nav.classList.add("transition");
    }, 300);
  });

  nav.classList.add("transition");

  hamburgers.addEventListener("click", () => {
    hamburgers.classList.toggle("active");
    document.querySelector(".mob-nav").classList.toggle("active");
    nav.classList.toggle("active");
  });

  const tabButtons = document.querySelectorAll(".tab-button");
  const tabPanes = document.querySelectorAll(".tab-pane");

  tabButtons.forEach((button) => {
    button.addEventListener("click", () => {
      const target = button.getAttribute("data-tab");

      tabButtons.forEach((btn) => btn.classList.remove("active"));
      button.classList.add("active");

      tabPanes.forEach((pane) => {
        if (pane.id === target) {
          pane.classList.add("active");
        } else {
          pane.classList.remove("active");
        }
      });
    });
  });

  const qaTabButtons = document.querySelectorAll(".qa-tab-button");
  const qaTabPanes = document.querySelectorAll(".qa-tab-pane");

  qaTabButtons.forEach((button) => {
    button.addEventListener("click", () => {
      const target = button.getAttribute("data-tab");

      qaTabButtons.forEach((btn) => btn.classList.remove("active"));
      button.classList.add("active");

      qaTabPanes.forEach((pane) => {
        if (pane.id === target) {
          pane.classList.add("active");
        } else {
          pane.classList.remove("active");
        }
      });
    });
  });

  const links = document.querySelectorAll('a[href^="#"]');

  links.forEach((link) => {
    link.addEventListener("click", function (event) {
      event.preventDefault();

      nav.classList.remove("active");

      const targetId = this.getAttribute("href").substring(1);
      const targetElement = document.getElementById(targetId);

      const tabTarget = [
        "corporate-engagement",
        "community-wellbeing",
        "event-collaboration",
      ];

      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - 100,
          behavior: "smooth",
        });
      }

      if (tabTarget.find((tab) => tab === targetId)) {
        window.scrollTo({
          top: document.getElementById("development").offsetTop,
          behavior: "smooth",
        });
        const target = tabTarget.find((tab) => tab === targetId);
        const targetButton = document.querySelector(`[data-tab="${target}"]`);
        if (targetButton) {
          tabButtons.forEach((btn) => btn.classList.remove("active"));
          targetButton.classList.add("active");

          tabPanes.forEach((pane) => {
            if (pane.id === target) {
              pane.classList.add("active");
            } else {
              pane.classList.remove("active");
            }
          });
        }
      }
    });
  });

  const milestoneSwiper = new Swiper(".mySwiper", {
    modules: [Navigation, Pagination],
    slidesPerView: 4,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 35,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 35,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 35,
      },
    },
  });

  const questions = document.querySelectorAll(".question");

  questions.forEach((question) => {
    question.addEventListener("click", () => {
      const answer = question.nextElementSibling;
      answer.classList.toggle("active");
    });
  });

  const productSwiper = new Swiper("#products_wrapper", {
    slidesPerView: 4,
    modules: [Pagination, Autoplay],
    spaceBetween: 30,
    autoplay: true,
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
      1280: {
        slidesPerView: 4,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });

  const bindCardClickEvents = () => {
    const cards = document.querySelectorAll(".products_card");
    const modal = document.getElementById("myModal");
    const iOS = document.getElementById("iOSLink");
    const Android = document.getElementById("androidLink");

    const handleOpen = () => {
      console.log("open");
      modal.style.display = "flex";
      const userAgent = handleChekcUserAgent();
      if (userAgent === "iOS") {
        Android.style.display = "none";
      } else if (userAgent === "Android") {
        iOS.style.display = "none";
      } else {
        Android.style.display = "flex";
        iOS.style.display = "flex";
      }
    };

    Array.from(cards).forEach((card) =>
      card.addEventListener("click", handleOpen)
    );

    const modalCloseBtn = document.getElementsByClassName("close")[0];
    modalCloseBtn.addEventListener("click", () => {
      modal.style.display = "none";
    });
  };

  const gettProcutReadTop = () => {
    const url =
      "https://api.myneighbor.com.tw/api/OfficialWeb/GetProcutReadTop";

    fetch(url, {
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // data
        renderProduct(data.ListProduct);
      })
      .catch((error) => {
        // error
        console.log(error);
      });
  };
  gettProcutReadTop();

  const renderProduct = (productData) => {
    let str = "";
    const productEl = document.getElementById("products_silde");
    productData.forEach((item) => {
      str += `
        <div class="swiper-slide">
            <div class="products_card">
                <div class="products_cardImg">
                    <img src=${item.Image} />
                </div>
                <div class="products_Info">
                    <div class="products_Name">
                        <div>${item.Name}</div>
                    </div>
                    <div class="products_Price">
                        <div>$ ${item.Price}</div>
                    </div>
                
                    <div class="products_City">
                        <div>${item.City}</div>
                        <div>${item.District}</div>
                        <div>${item.Road}</div>
                    </div>
                </div>
            </div>
        </div>
    `;
    });

    str += ` <div class="swiper-slide">
          
        <div class="products_ReadMoreButtonWrapper">
            <div id="products_ReadMoreContainer">
                <button class="products_ReadMore">
                  <i class="fa-solid fa-angle-right"></i>
                </button>
            <div class="products_ReadMoreText">
                查看更多
            </div>
                </div>
          
        </.div>
        </.div>`;

    productEl.innerHTML = str;

    bindCardClickEvents();
  };
});
